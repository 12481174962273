<template>
  <div class="emergency-trainings-container">
    <VueText style="margin: 20px 0;" sizeLevel="8" weightLevel="2" class="text">
      Afetlere Hazır Olmak</VueText
    >
    <div class="emergency-trainings-list">
      <div
        class="emergency-trainings-item"
        v-for="(item, index) in videoUrlList"
        :id="`emergency-video-${index}`"
        :key="index"
        v-html="item.url"
      />
    </div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import { Common } from '@/services/Api/index';

export default {
  name: 'EmergencyTrainings',
  components: { VueText },
  data() {
    return {
      videoUrlList: [],
    };
  },
  mounted() {
    this.getEmergencyTrainings();
  },
  methods: {
    getEmergencyTrainings() {
      Common.getEmergencyTrainings().then(res => {
        const {
          Data: { videoUrlList },
        } = res.data;
        this.videoUrlList = videoUrlList;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.emergency-trainings {
  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    margin: 10px 0 !important;
    @media screen and (min-device-width: 768px) {
      max-width: 50%;
    }
  }
}
</style>
